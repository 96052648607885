import React, { useState, useEffect } from "react";
import { db } from "../config/firebase-config";
import {
  collection,
  onSnapshot,
  query,
  orderBy,
  limit,
} from "firebase/firestore";

import Card from "react-bootstrap/Card";
import { Container } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

const ProtokollFahrzeug = (props) => {
  const [protokoll, setProtokoll] = useState([]);
  const [protokollFilter, setProtokollFilter] = useState([]);

  useEffect(() => {
    props.setSearchString("");
  }, []);

  useEffect(() => {
    const dbLink = "fahrzeuge/" + props.fahrzeugId + "/protokoll";
    const protokollRef = collection(db, dbLink);

    //const queryProtokoll = query(protokollRef);
    const queryProtokoll = query(
      protokollRef,
      orderBy("datum", "desc"),
      limit(500)
    );

    const unsubscribe = onSnapshot(queryProtokoll, (snapshot) => {
      let protokoll = [];
      snapshot.forEach((doc) => {
        protokoll.push({ ...doc.data(), id: doc.id });
      });

      setProtokoll(protokoll);
      console.log("Fahrzeughistorie:", protokoll);
    });

    return () => {
      unsubscribe();
    };
  }, [props.fahrzeugId]);

  useEffect(() => {
    const filterProtokoll = (item) => {
      return (
        item.mangelId
          .toLowerCase()
          .includes(props.searchString.toLowerCase()) ||
        item.beschreibung
          .toLowerCase()
          .includes(props.searchString.toLowerCase()) ||
        statusToText(item.status)
          .toLowerCase()
          .includes(props.searchString.toLowerCase()) ||
        prioToText(item.prio)
          .toLowerCase()
          .includes(props.searchString.toLowerCase()) ||
        kategorieToText(item.kategorie)
          .toLowerCase()
          .includes(props.searchString.toLowerCase()) ||
        item.kontaktName
          .toLowerCase()
          .includes(props.searchString.toLowerCase())
      );
    };

    let protokollTmp = [];

    // console.log("Filter im Protokoll aufgerufen ANFANG!");
    // Filtern der Fahrzeuge mit searchString
    if (props.searchString !== "") {
      // console.log("Filter im Protokoll aufgerufen! SCHLEIFE");
      protokollTmp = protokoll.filter(filterProtokoll);
    }

    if (props.searchString.length > 0) {
      setProtokollFilter(protokollTmp);
    } else {
      setProtokollFilter(protokoll);
    }
  }, [props.searchString, protokoll]);

  const statusToText = (status) => {
    let result = "";

    switch (status) {
      case "neu":
        result = "Neu";
        break;
      case "offen":
        result = "Offen";
        break;
      case "erledigt":
        result = "Erledigt";
        break;
      case "backlog":
        result = "Zurückgestellt";
        break;
      default:
        result = "Fehler";
    }

    return result;
  };

  const prioToText = (prio) => {
    switch (prio) {
      case "1":
        return "Hoch";
      case "2":
        return "Mittel";
      case "3":
        return "Niedrig";
      default:
        return "Ohne";
    }
  };

  const kategorieToText = (kategorie) => {
    switch (kategorie) {
      case "1":
        return "Kategorie 1";
      case "2":
        return "Kategorie 2";
      case "3":
        return "Kategorie 3";
      default:
        return "Kategorie 4";
    }
  };

  const mangelIdToString = (id) => {
    let result = "";
    result = id.substring(0, 6);
    return result.toUpperCase();
  };

  return (
    <Container>
      <Card className="statusColumn">
        <Card.Header style={{ backgroundColor: "lightgray" }}>
          <div className="settingHeader">
            <div className="settingTitle">
              Fahrzeughistorie {props.pageKennzeichen}
            </div>
            <div>
              <Button
                variant="secondary"
                className="btn-header"
                onClick={() => {
                  console.log("btn clicked", props);
                  props.changePage("fahrzeug");
                }}
              >
                Zurück
              </Button>
              <Button
                variant="primary"
                className="btn-header"
                onClick={() => {}}
                disabled
              >
                CSV Export
              </Button>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover className="protokollTable">
            <thead>
              <tr>
                <th className="col-1">Datum, Uhrzeit</th>
                <th className="col-1">Mangel</th>
                <th className="col-1">Wer</th>
                <th className="col-1">Status</th>
                <th className="col-1">Priorität</th>
                <th className="col-2">Kategorie</th>
                <th className="col-1">Kilometer</th>
                <th className="col-4">Beschreibung</th>
              </tr>
            </thead>
            <tbody>
              {protokollFilter.map((item, index) => {
                const date = new Date(item.datum.seconds * 1000);
                const dateProtokoll =
                  date.getDate() +
                  "." +
                  date.getMonth() +
                  "." +
                  date.getFullYear() +
                  ", " +
                  date.getHours() +
                  ":" +
                  date.getMinutes() +
                  ":" +
                  date.getSeconds() +
                  " Uhr";

                return (
                  <tr key={index}>
                    <td>{dateProtokoll}</td>
                    <td>
                      <a
                        href="#"
                        onClick={() => {
                          props.setSearchString(
                            mangelIdToString(item.mangelId)
                          );
                        }}
                      >
                        {mangelIdToString(item.mangelId)}
                      </a>
                    </td>
                    <td>{item.kontaktName}</td>
                    <td>
                      Aktuell: {statusToText(item.status)}
                      {"statusOld" in item ? (
                        <>
                          <br />
                          <span className="protokollAlt">
                            Vorher: {statusToText(item.statusOld)}
                          </span>{" "}
                        </>
                      ) : undefined}
                    </td>
                    <td>
                      Aktuell: {prioToText(item.prio)}
                      {"prioOld" in item ? (
                        <>
                          <br />
                          <span className="protokollAlt">
                            Vorher: {prioToText(item.prioOld)}
                          </span>{" "}
                        </>
                      ) : undefined}
                    </td>
                    <td>
                      Aktuell: {kategorieToText(item.kategorie)}
                      {"kategorieOld" in item ? (
                        <>
                          <br />
                          <span className="protokollAlt">
                            Vorher: {kategorieToText(item.kategorieOld)}
                          </span>{" "}
                        </>
                      ) : undefined}
                    </td>
                    <td>{item.kilometer} km</td>
                    <td>{item.beschreibung}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer className="statusFooter">
          {protokollFilter.length === 0 ? "Keine Einträge" : undefined}
          {protokollFilter.length === 1 ? "1 Eintrag" : undefined}
          {protokollFilter.length > 1
            ? protokollFilter.length + " Einträge"
            : undefined}
        </Card.Footer>
      </Card>
    </Container>
  );
};

export default ProtokollFahrzeug;
