import React, { useEffect, useState } from "react";
import { db } from "../config/firebase-config";
import {
  doc,
  updateDoc,
  arrayUnion,
  addDoc,
  setDoc,
  collection,
} from "firebase/firestore";
import moment from "moment";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import MsgBox from "./MsgBox";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import axios from "axios";

const ModalMangelIntern = (props) => {
  const today = new Date();

  const [kategorie, setKategorie] = useState("");
  const [kontaktVorname, setKontaktVorname] = useState("");
  const [kontaktNachname, setKontaktNachname] = useState("");
  const [kontaktEmail, setKontaktEmail] = useState("");
  const [kontaktTelefon, setKontaktTelefon] = useState("");
  const [kilometer, setKilometer] = useState("");
  const [beschreibung, setBeschreibung] = useState("");
  const [fahrzeugId, setFahrzeugId] = useState("");
  const [kennzeichenList, setKennzeichenList] = useState([]);
  const [cannotSave, setCannotSave] = useState(true);

  useEffect(() => {
    setFahrzeugId(props.fahrzeug.id);
  }, [props.fahrzeug]);

  const changeKm = (e) => {
    let valueTmp = e.target.value;

    const numericString = valueTmp.replace(/\D/g, "");

    // Wenn der String leer ist, gib eine leere Zeichenkette zurück
    if (numericString === "") return "";

    // Wandelt die Zahl in einen Integer um
    const number = parseInt(numericString, 10);

    setKilometer(number.toLocaleString("de-DE"));
  };

  const handleSave = () => {
    console.log("save new Mangel mit id", fahrzeugId);

    const path = "fahrzeuge/" + fahrzeugId + "/maengel";
    const docRef = collection(db, path);
    // const docRef = doc(db, "fahrzeuge");

    let data = {
      new: true,
      status: "offen",
      datumAngelegt: today,
      datumLastUpdate: today,
      kategorie: kategorie,
      kontaktName: kontaktVorname + " " + kontaktNachname,
      kontaktEmail: kontaktEmail,
      kontaktTelefon: kontaktTelefon,
      priorität: 4,
      kilometer: kilometer,
      historie: [
        {
          beschreibung: beschreibung,
          nutzerkreis: "",
          datum: today,
        },
      ],
    };

    // Atomically add a new region to the "regions" array field.
    addDoc(docRef, data).then((doc) => {
      writeToProtokoll(doc.id);
    });

    handleClose();
  };

  const writeToProtokoll = (id) => {
    const path = "fahrzeuge/" + fahrzeugId + "/protokoll";
    const docRef = collection(db, path);

    let data = {
      status: "neu",
      kategorie: kategorie,
      kontaktName: kontaktVorname + " " + kontaktNachname,
      priorität: 4,
      kilometer: kilometer,
      beschreibung: beschreibung,
      nutzerkreis: "",
      datum: today,
      mangelId: id,
    };

    // Atomically add a new region to the "regions" array field.
    addDoc(docRef, data);
  };

  const handleClose = () => {
    props.close();
  };

  return (
    <>
      <Modal show={props.showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Mangel für Fahrzeug eintragen</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Container>
            <Form>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Kennzeichen</Form.Label>
                    <Form.Select
                      name="modalstatus"
                      onChange={(e) => {
                        setFahrzeugId(e.target.value);
                      }}
                      disabled
                    >
                      <option value={props.fahrzeug.id}>
                        {props.fahrzeug.kennzeichen}
                      </option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Kilometerstand</Form.Label>
                    <Form.Control
                      type="text"
                      id="inputKilomter"
                      value={kilometer}
                      onChange={(e) => {
                        changeKm(e);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Vorname</Form.Label>
                    <Form.Control
                      type="text"
                      id="inputVorname"
                      value={kontaktVorname}
                      onChange={(e) => {
                        setKontaktVorname(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Nachname</Form.Label>
                    <Form.Control
                      type="text"
                      id="inputNachname"
                      value={kontaktNachname}
                      onChange={(e) => {
                        setKontaktNachname(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>E-Mail</Form.Label>
                    <Form.Control
                      type="text"
                      id="inputEmail"
                      value={kontaktEmail}
                      onChange={(e) => {
                        setKontaktEmail(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Telefon</Form.Label>
                    <Form.Control
                      type="text"
                      id="inputTelefon"
                      value={kontaktTelefon}
                      onChange={(e) => {
                        setKontaktTelefon(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Kategorie des Mangels</Form.Label>
                    <Form.Select
                      name="modalstatus"
                      value={kategorie}
                      onChange={(e) => {
                        setKategorie(e.target.value);
                      }}
                    >
                      <option value="">Bitte wählen...</option>
                      <option value="1">Kategorie 1</option>
                      <option value="2">Kategorie 2</option>
                      <option value="3">Kategorie 3</option>
                      <option value="4">Kategorie 4</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Mangelbeschreibung</Form.Label>
                    <Form.Control
                      as="textarea"
                      style={{ height: "150px" }}
                      onChange={(e) => {
                        setBeschreibung(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Abbrechen
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Meldung abschicken
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalMangelIntern;
