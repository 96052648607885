import React, { useState } from "react";
import moment from "moment";

import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ModalUpdateMangel from "./ModalUpdateMangel";

const MangelBox = (props) => {
  const [showModal, setShowModal] = useState(false);

  let prio = "prio1";
  let prioText = "Hohe Priorität";

  switch (props.mangel.priorität) {
    case "1":
      prio = "prio1";
      prioText = "Hohe Priorität";
      break;
    case "2":
      prio = "prio2";
      prioText = "Mittlere Priorität";
      break;
    case "3":
      prio = "prio3";
      prioText = "Niedriege Priorität";
      break;
    default:
      prio = "prio4";
      prioText = "Ohne Priorität";
      break;
  }
  if (props.mangel.status === "erledigt") prio = "prio4";

  const prioToText = (prio) => {
    switch (prio) {
      case "1":
        return "Hoch";
      case "2":
        return "Mittel";
      case "3":
        return "Niedrig";
      default:
        return "Ohne";
    }
  };

  const statusToText = (status) => {
    switch (status) {
      case "erledigt":
        return "Erledigt";
      case "backlog":
        return "Zurückgestellt";
      default:
        return "Offen";
    }
  };

  const kategorieToText = (kategorie) => {
    switch (kategorie) {
      case "1":
        return "Kategorie 1";
      case "2":
        return "Kategorie 2";
      case "3":
        return "Kategorie 3";
      default:
        return "Kategorie 4";
    }
  };

  const displayPrioUpdate = (mangel) => {
    if (mangel.prioNew !== mangel.prioOld) {
      return (
        <p className="mangelUpdateInfo">
          Priorität geändert von "{prioToText(mangel.prioOld)}" auf "
          {prioToText(mangel.prioNew)}"
        </p>
      );
    }
  };

  const displayStatusUpdate = (mangel) => {
    if (mangel.statusNew !== mangel.statusOld) {
      return (
        <p className="mangelUpdateInfo">
          Status geändert von "{statusToText(mangel.statusOld)}" auf "
          {statusToText(mangel.statusNew)}"
        </p>
      );
    }
  };

  const displayKategorieUpdate = (mangel) => {
    if (mangel.kategorieNew !== mangel.kategorieOld) {
      return (
        <p className="mangelUpdateInfo">
          Kategorie geändert von "{kategorieToText(mangel.kategorieOld)}" auf "
          {kategorieToText(mangel.kategorieNew)}"
        </p>
      );
    }
  };

  const handleDoubleClick = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  // const reversedHistorie = props.mangel.historie.toReversed();

  return (
    <>
      <ModalUpdateMangel
        showModal={showModal}
        close={handleClose}
        mangel={props.mangel}
        fahrzeug={props.fahrzeug}
      />
      <div
        className={"mangel " + prio}
        onDoubleClick={() => {
          handleDoubleClick();
        }}
      >
        <div className="mangelHeader">
          <div className="mangelInfo">
            {props.mangel.kontaktName}
            <br />
            Telefon: {props.mangel.kontaktTelefon}
            <br />
            E-Mail:{" "}
            <a href={"mailto:" + props.mangel.kontaktEmail}>
              {props.mangel.kontaktEmail}
            </a>
          </div>
          <div className="mangelPrio">
            {props.mangel.kilometer} km
            <br />
            {kategorieToText(props.mangel.kategorie)}
            <br />
            {prioText}
          </div>
        </div>
        {props.mangel.historie.map((item, index) => {
          let userTmp = item.nutzerkreis;
          if (userTmp === "") userTmp = props.mangel.kontaktName;

          let showEntry = false;
          if (index === 0 || index === props.mangel.historie.length - 1)
            showEntry = true;
          if (props.showAll) showEntry = true;

          if (showEntry) {
            return (
              <div className="mangelEntry" key={item.id}>
                <div>
                  <p
                    style={{
                      fontWeight: "bold",
                      marginBottom: "5px",
                      borderBottom: "1px solid gray",
                    }}
                  >
                    {userTmp},{" "}
                    {moment
                      .unix(item.datum.seconds)
                      .format("DD.MM.YYYY, HH:MM")}{" "}
                    Uhr:
                  </p>
                  <p className="mangelInfoText">{item.beschreibung}</p>
                  {/* {displayPrioUpdate(item)} */}
                  {displayStatusUpdate(item)}
                  {displayKategorieUpdate(item)}
                </div>
              </div>
            );
          } else return undefined;
        })}
      </div>
    </>
  );
};

export default MangelBox;
