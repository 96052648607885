import React, { useEffect, useState } from "react";
import { db } from "../config/firebase-config";
import {
  doc,
  updateDoc,
  arrayUnion,
  addDoc,
  collection,
} from "firebase/firestore";
import moment from "moment";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

const ModalUpdateMangel = (props) => {
  const today = new Date();

  const [kategorie, setKategorie] = useState("");
  const [kategorieOld, setKategorieOld] = useState("");
  const [status, setStatus] = useState("");
  const [statusOld, setStatusOld] = useState("");
  const [kontaktName, setKontaktName] = useState("");
  const [kontaktEmail, setKontaktEmail] = useState("");
  const [kontaktTelefon, setKontaktTelefon] = useState("");
  const [kilometer, setKilometer] = useState("");
  const [beschreibung, setBeschreibung] = useState("");
  const [prio, setPrio] = useState(4);
  const [prioOld, setPrioOld] = useState(4);

  useEffect(() => {
    setStatus(props.mangel.status);
    setStatusOld(props.mangel.status);
    setPrio(props.mangel.priorität);
    setPrioOld(props.mangel.priorität);
    setKategorie(props.mangel.kategorie);
    setKategorieOld(props.mangel.kategorie);
    setKontaktName(props.mangel.kontaktName);
    setKontaktEmail(props.mangel.kontaktEmail);
    setKontaktTelefon(props.mangel.kontaktTelefon);
    setKilometer(props.mangel.kilometer);
  }, [props.mangel]);

  const handleSave = () => {
    const dbLink = "fahrzeuge/" + props.fahrzeug.id + "/maengel";
    const docRef = doc(db, dbLink, props.mangel.id);

    let data = {
      new: false,
      status: status,
      datumAngelegt: today,
      datumLastUpdate: today,
      kategorie: kategorie,
      kontaktName: kontaktName,
      kontaktEmail: kontaktEmail,
      kontaktTelefon: kontaktTelefon,
      priorität: prio,
      kilometer: kilometer,
      historie: arrayUnion({
        beschreibung: beschreibung,
        nutzerkreis: "Admin",
        datum: today,
        statusOld: statusOld,
        statusNew: status,
        prioOld: prioOld,
        prioNew: prio,
        kategorieOld: kategorieOld,
        kategorieNew: kategorie,
      }),
    };

    // Atomically add a new region to the "regions" array field.
    updateDoc(docRef, data).then(() => {
      writeToProtokoll(props.mangel.id);
    });

    props.close();
  };

  const writeToProtokoll = (id) => {
    const path = "fahrzeuge/" + props.fahrzeug.id + "/protokoll";
    const docRef = collection(db, path);

    let data = {
      status: status,
      statusOld: statusOld,
      statusNew: status,
      prioOld: prioOld,
      prioNew: prio,
      kategorieOld: kategorieOld,
      kategorieNew: kategorie,
      kategorie: kategorie,
      kontaktName: "Admin",
      priorität: prio,
      kilometer: kilometer,
      beschreibung: beschreibung,
      nutzerkreis: "",
      datum: today,
      mangelId: id,
    };

    // Atomically add a new region to the "regions" array field.
    addDoc(docRef, data);
  };

  const handleClose = () => {
    props.close();
  };

  return (
    <>
      <Modal show={props.showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Fahrzeugmangel</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Container>
            <Form>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Status des Mangels</Form.Label>
                    <Form.Select
                      name="modalstatus"
                      value={status}
                      onChange={(e) => {
                        setStatus(e.target.value);
                      }}
                    >
                      <option value="offen">Offen</option>
                      <option value="backlog">Zurückgestellt</option>
                      <option value="erledigt">Erledigt</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Priorität</Form.Label>
                    <Form.Select
                      name="modalPrio"
                      value={prio}
                      onChange={(e) => {
                        setPrio(e.target.value);
                      }}
                    >
                      <option value="1">Hohe Priorität</option>
                      <option value="2">Mittlere Priorität</option>
                      <option value="3">Niedriege Priorität</option>
                      <option value="4">Ohne Priorität</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Kategorie des Mangels</Form.Label>
                    <Form.Select
                      name="modalstatus"
                      value={kategorie}
                      onChange={(e) => {
                        setKategorie(e.target.value);
                      }}
                    >
                      <option value="">Bitte wählen...</option>
                      <option value="1">Kategorie 1</option>
                      <option value="2">Kategorie 2</option>
                      <option value="3">Kategorie 3</option>
                      <option value="4">Kategorie 4</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Bemerkung</Form.Label>
                    <Form.Control
                      as="textarea"
                      style={{ height: "150px" }}
                      value={beschreibung}
                      onChange={(e) => {
                        setBeschreibung(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Abbrechen
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Update speichern
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalUpdateMangel;
